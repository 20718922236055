import "./zoom.css";
import React, { useState } from "react";
//assets
import { assets } from "../../assets";

import {
  TreeApples,
  TreeLeaves,
  TreeImageWrappers,
  Cherry,
} from "../../styling/styles";
import Tree from "../branches/Tree";
import { InsertLink } from "@mui/icons-material";

interface WindowOptions {
  [key: string]: string;
}

const ExperienceTree: React.FC = () => {
  const [slideImages, setSlideImages] = useState({
    mhw: assets.mhw.first,
    map: assets.map.mapfirst,
    dir: assets.dir.dir1,
    crm: assets.crm.crm,
    gog: assets.gog.gogreen,
    ppi: assets.ppi.ppi,
    nda: assets.nda.nda1,
  });

  const openWindow = (selected: string) => {
    const windowOptions: WindowOptions = {
      DIR: "https://dustinsmith.tech/DIR",
      map: "https://specksmap.online",
      debt: "https://nationaldebtadvisers.co.uk/",
      ppi: "https://www.yourppitaxrebate.co.uk/",
      green: "https://go-green.opopservices.co.uk/",
    };

    const url = windowOptions[selected];
    if (url) {
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const handleImageHover = (
    event: {
      target: {
        style: {
          maxHeight: string;
          maxWidth: string;
          width: string;
          height: string;
        };
      };
    },
    image: any
  ) => {
    event.target.style.width = "auto";
    event.target.style.height = "13.5rem";
    event.target.style.maxHeight = "13.5rem";

    let keys = Object.keys(assets[image]); // Get all keys from the assets object
    // console.log(keys);
    let index = keys.indexOf(`${image}`); // Get the index of the current image
    // console.log(index);
    // Start an interval to cycle through the assets
    const interval = setInterval(() => {
      index = (index + 1) % keys.length; // Increment the index circularly
      const nextImageKey = keys[index]; // Get the next image key
      const nextImage = assets[image][nextImageKey]; // Get the next image value

      setSlideImages((prevState) => ({
        ...prevState,
        [image]: nextImage,
      }));

      // Stop the interval after cycling through all images
      if (index === keys.indexOf(`${image}`)) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleImageLeave = (event: {
    target: { style: { width: string; height: string } };
  }) => {
    event.target.style.width = "";
    event.target.style.height = "";
  };

  return (
    <>
      <Tree name="his experience">
        <Tree name="academically">
          <Tree name="myHealth Ward">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.mhw}
                alt="myHealth Ward"
                onMouseOver={(event: any) => handleImageHover(event, "mhw")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" style={{ cursor: "not-allowed" }} />
            </TreeImageWrappers>

            <Tree name="summary">
              <Cherry>
                A complex project that is currently in development and consists
                of multiple applications, all of which are served through a
                central API. The main application, MyHealth Ward, encompasses
                various features including social networking, chat
                functionality, and a marketplace. <br />
                Demonstration available upon request.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Front-end</li>
                  <li>React / JavaScript</li>
                  <li>ContextAPI</li>
                  <li>SASS / CSS</li>
                </ul>
                <ul className="api-list">
                  <li className="list-heading">API</li>
                  <li>Node.js</li>
                  <li>MongoDB / Mongoose</li>
                  <li>Express</li>
                </ul>
              </div>
            </Tree>
          </Tree>
          <Tree name="specks map">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.map}
                alt="specks map"
                onMouseOver={(event: any) => handleImageHover(event, "map")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" onClick={() => openWindow("map")} />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                A passion project aimed at eventually offering it to schools and
                education providers upon completion. Specks is an extensive
                mapping system that incorporates multiple tile layers and
                dynamic data covering over 200 countries.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Single-Page-App</li>
                  <li>HTML 5</li>
                  <li>CSS 3</li>
                  <li>JavaScript</li>
                  <li>PHP</li>
                  <li>MariaDB / MySQL</li>
                </ul>
              </div>
            </Tree>
          </Tree>
          <Tree name="company directory">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.dir}
                alt="crud"
                onMouseOver={(event: any) => handleImageHover(event, "dir")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" onClick={() => openWindow("DIR")} />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                A mobile and desktop SPA with basic create, read, update and
                delete functions with error handling and a SQL database.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Single-Page-App</li>
                  <li>HTML 5</li>
                  <li>CSS 3</li>
                  <li>JavaScript</li>
                  <li>PHP</li>
                  <li>MariaDB / MySQL</li>
                </ul>
              </div>
            </Tree>
          </Tree>
        </Tree>

        <Tree name="professionally">
          <Tree name="National Debt Advisers">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.nda}
                alt="debt advisers"
                onMouseOver={(event: any) => handleImageHover(event, "nda")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" onClick={() => openWindow("debt")} />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                An intricate mobile and desktop application with multiple
                outcomes is rendered based on user choices. This application
                collects user data and generates a comprehensive report
                encompassing credit history and, in most cases, the entirety of
                their financial history.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Front-end</li>
                  <li>React / Typescript</li>
                  <li>Redux</li>
                  <li>SASS / CSS</li>
                </ul>
                <ul className="api-list">
                  <li className="list-heading">API</li>
                  <li>Node.js</li>
                  <li>MySQL / Sequelize</li>
                  <li>Express</li>
                  <li>Mailgun</li>
                  <li>Google API</li>
                  <li>Data8 API</li>
                  <li>FastDox API</li>
                  <li>AWS hosted</li>
                </ul>
                <ul className="tracker-list">
                  <li className="list-heading">Trackers</li>
                  <li>Google mouseflow</li>
                  <li>Meta pixel</li>
                </ul>
              </div>
            </Tree>
          </Tree>
          <Tree name="Your PPI Tax Rebate">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.ppi}
                alt="ppi"
                onMouseOver={(event: any) => handleImageHover(event, "ppi")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" onClick={() => openWindow("ppi")} />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                A fairly straightforward lead generation and marketing
                application with both front-end and back-end components. It
                collects user data, signatures, and consent, which are then
                transmitted to a client, specifically a law firm in this case.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Front-end</li>
                  <li>React / Typescript</li>
                  <li>Redux</li>
                  <li>SASS / CSS</li>
                </ul>
                <ul className="api-list">
                  <li className="list-heading">API</li>
                  <li>Node.js</li>
                  <li>Express</li>
                  <li>Mailgun</li>
                  <li>MySQL / Sequelize</li>
                  <li>Google API</li>
                  <li>Data8 API</li>
                  <li>AWS hosted</li>
                </ul>
                <ul className="tracker-list">
                  <li className="list-heading">Trackers</li>
                  <li>Google mouseflow</li>
                  <li>Meta pixel</li>
                </ul>
              </div>
            </Tree>
          </Tree>
          <Tree name="GoGreen">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.gog}
                alt="green"
                onMouseOver={(event: any) => handleImageHover(event, "gog")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink
                className="zoom"
                onClick={() => openWindow("green")}
              />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                A website focused on domestic green energy lead generation,
                designed with an engaging layout to capture user data. The
                collected data is stored in both SQL and Google Sheets, which
                are then provided to the end-client for sales leads.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Front-end</li>
                  <li>React / Typescript</li>
                  <li>Redux</li>
                  <li>SASS / CSS</li>
                </ul>
                <ul className="api-list">
                  <li className="list-heading">API</li>
                  <li>Node.js</li>
                  <li>Express</li>
                  <li>Mailgun</li>
                  <li>MySQL / Sequelize</li>
                  <li>Google API</li>
                  <li>Data8 API</li>
                  <li>AWS hosted</li>
                </ul>
                <ul className="tracker-list">
                  <li className="list-heading">Trackers</li>
                  <li>Google mouseflow</li>
                  <li>Meta pixel</li>
                </ul>
              </div>
            </Tree>
          </Tree>
          <Tree name="Accounting CRM">
            <TreeImageWrappers>
              <TreeLeaves
                src={slideImages.crm}
                alt="crm"
                onMouseOver={(event: any) => handleImageHover(event, "crm")}
                onMouseLeave={handleImageLeave}
              />
              <InsertLink className="zoom" style={{ cursor: "not-allowed" }} />
            </TreeImageWrappers>
            <Tree name="summary">
              <Cherry>
                An internal, all-encompassing CRM system that is utilized by
                around 50 employees to manage their accountancy firm and
                clients. The live site is not publicly accessible.
              </Cherry>
            </Tree>
            <Tree name="stack">
              <div className="tech-lists">
                <ul className="frontend-list">
                  <li className="list-heading">Front-end</li>
                  <li>React / Typescript</li>
                  <li>Redux</li>
                  <li>SASS / CSS</li>
                </ul>
                <ul className="api-list">
                  <li className="list-heading">API</li>
                  <li>Node.js</li>
                  <li>MySQL / Sequelize</li>
                  <li>Express</li>
                </ul>
                <ul className="tracker-list">
                  <li className="list-heading">Trackers</li>
                  <li>Google mouseflow</li>
                  <li>Meta pixel</li>
                </ul>
              </div>
            </Tree>
          </Tree>
        </Tree>
      </Tree>
    </>
  );
};

export default ExperienceTree;
