import { Cherry, Container } from "./styling/styles";
import "./app.scss";
import Tree from "./components/branches/Tree";
import { useEffect, useState } from "react";
import { assets } from "./assets";
import ExperienceTree from "./components/experience/experienceTree";
import EmailValidator from "./components/emailer/emailer";

function App(): JSX.Element {
  // background colour
  const [backgroundWhite, setBackgroundWhite] = useState<boolean>(false);
  const [backgroundBlack, setBackgroundBlack] = useState<boolean>(false);
  const [backgroundPurple, setBackgroundPurple] = useState<boolean>(false);
  const [backgroundGreen, setBackgroundGreen] = useState<boolean>(false);
  const [backgroundOrange, setBackgroundOrange] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundWhite
      ? "white"
      : "transparent";
  }, [backgroundWhite]);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundBlack
      ? "#000"
      : "transparent";
  }, [backgroundBlack]);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundPurple
      ? "purple"
      : "transparent";
  }, [backgroundPurple]);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundGreen
      ? "green"
      : "transparent";
  }, [backgroundGreen]);

  useEffect(() => {
    document.body.style.backgroundColor = backgroundOrange
      ? "orange"
      : "transparent";
  }, [backgroundOrange]);

  const resetBG = () => {
    setBackgroundWhite(false);
    setBackgroundBlack(false);
    setBackgroundGreen(false);
    setBackgroundPurple(false);
    setBackgroundOrange(false);
    document.body.style.backgroundColor = "transparent";
  };

  // text colour
  const [textWhite, setTextWhite] = useState<boolean>(false);
  const [textBlack, setTextBlack] = useState<boolean>(false);
  const [textPurple, setTextPurple] = useState<boolean>(false);
  const [textGreen, setTextGreen] = useState<boolean>(false);
  const [textOrange, setTextOrange] = useState<boolean>(false);

  const [textColor, setTextColor] = useState<string>("white");

  const whiteText = () => {
    setTextWhite(!textWhite);
    setTextColor(textWhite ? "white" : "white");
  };
  const blackText = () => {
    setTextBlack(!textBlack);
    setTextColor(textBlack ? "white" : "black");
  };
  const purpleText = () => {
    setTextPurple(!textPurple);
    setTextColor(textPurple ? "white" : "purple");
  };
  const greenText = () => {
    setTextGreen(!textGreen);
    setTextColor(textGreen ? "white" : "green");
  };
  const orangeText = () => {
    setTextOrange(!textOrange);
    setTextColor(textOrange ? "white" : "orange");
  };

  const resetText = () => {
    setTextWhite(false);
    setTextBlack(false);
    setTextPurple(false);
    setTextGreen(false);
    setTextOrange(false);
    setTextColor("white");
  };

  // text sizing
  const [fontSizer, setFontSizer] = useState<number>(1.1);

  const increaseFont = () => {
    setFontSizer(fontSizer + 0.1);
  };
  const max = fontSizer >= 2;

  const decreaseFont = () => {
    setFontSizer(fontSizer - 0.1);
  };
  const min = fontSizer <= 0.5;

  return (
    <Container fontColor={textColor} fontSize={fontSizer}>
      <Tree name="dustin" defaultOpen={false}>
        <Tree name="about">
          <Tree name="this design">
            <Cherry>
              embodies simplicity, familiarity, and ease of use. Built to be
              intuitive for both mobile and desktop, the focus is on delivering
              information quickly, efficiently, without sacrificing engagement
              but with simplexity. Built with React/TypeScript & PHP.
            </Cherry>
          </Tree>
          <Tree name="the developer">
            <Cherry>
              driven by a passion for lead generation, sales, and marketing web
              builds, my expertise lies in developing simple to complex online
              solutions. I specialie in creating engaging experiences that
              effectively capture and store user data for commercial purposes.
            </Cherry>
          </Tree>
        </Tree>
        <ExperienceTree />
        <Tree name="say hello">
          <EmailValidator />
        </Tree>
        <Tree name="accessibility">
          <Tree name="text color">
            <div className="textColours">
              <div
                className={`${textWhite ? "white-text-off" : "white-text-on"}`}
                onClick={whiteText}
              />
              <div
                className={`${textBlack ? "black-text-off" : "black-text-on"}`}
                onClick={blackText}
              />
              <div
                className={`${textGreen ? "green-text-off" : "green-text-on"}`}
                onClick={greenText}
              />
              <div
                className={`${
                  textPurple ? "purple-text-off" : "purple-text-on"
                }`}
                onClick={purpleText}
              />
              <div
                className={`${
                  textOrange ? "orange-text-off" : "orange-text-on"
                }`}
                onClick={orangeText}
              />
              <div className="reset-colours" onClick={resetText}>
                <img
                  src={assets.resetIcon}
                  alt="Reset"
                  className="reset-icon"
                />
              </div>
            </div>
          </Tree>
          <Tree name="text size">
            <div className="fontSizingContainer">
              <button
                className="plus"
                style={{ cursor: "pointer" }}
                onClick={increaseFont}
                disabled={max}
              >
                +
              </button>
              <button
                className="minus"
                style={{ cursor: "pointer" }}
                onClick={decreaseFont}
                disabled={min}
              >
                -
              </button>
            </div>
          </Tree>
          <Tree name="background color">
            <div className="bgColours">
              <div
                className={`${
                  backgroundWhite ? "white-box-off" : "white-box-on"
                }`}
                onClick={() => {
                  setBackgroundWhite(!backgroundWhite);
                }}
              />
              <div
                className={`${
                  backgroundBlack ? "black-box-off" : "black-box-on"
                }`}
                onClick={() => {
                  setBackgroundBlack(!backgroundBlack);
                }}
              />
              <div
                className={`${
                  backgroundGreen ? "green-box-off" : "green-box-on"
                }`}
                onClick={() => {
                  setBackgroundGreen(!backgroundGreen);
                }}
              />
              <div
                className={`${
                  backgroundPurple ? "purple-box-off" : "purple-box-on"
                }`}
                onClick={() => {
                  setBackgroundPurple(!backgroundPurple);
                }}
              />
              <div
                className={`${
                  backgroundOrange ? "orange-box-off" : "orange-box-on"
                }`}
                onClick={() => {
                  setBackgroundOrange(!backgroundOrange);
                }}
              />
              <div className="reset-colours" onClick={resetBG}>
                <img
                  src={assets.resetIcon}
                  alt="Reset"
                  className="reset-icon"
                />
              </div>
            </div>
          </Tree>
        </Tree>
      </Tree>
    </Container>
  );
}

export default App;
