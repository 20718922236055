import resetIcon from "./reset.svg";
//pro
// import ppi from "./ppi.png";
// import nda from "./nda.png";
// import gogreen from "./gogreen.png";
// import crm from "./crm.png";
//personal
// import mhw from "./mhwWhite.jpg";
import specks from "./map.jpg";
import crud from "./crud.png";
//other
import clips from "./clips.svg";
import wave from "./wave.svg";

//pro
import proLogin from "./folio/mhw/specialist/prologin.jpg";
import proReg from "./folio/mhw/specialist/proregister.jpg";
import first from "./folio/mhw/specialist/spec1.jpg";
import spec2 from "./folio/mhw/specialist/spec2.jpg";
import spec3 from "./folio/mhw/specialist/spec3.jpg";

//user
import book from "./folio/mhw/user/bookSpec.jpg";
import book1 from "./folio/mhw/user/bookSpec1.jpg";
import chat from "./folio/mhw/user/userChat2Chat.jpg";
import userlogin from "./folio/mhw/user/userlogin.jpg";

//control
import access from "./folio/mhw/control/access.jpg";
import dash1 from "./folio/mhw/control/dashboard.jpg";
import dash2 from "./folio/mhw/control/dashboard2.jpg";
import dash3 from "./folio/mhw/control/dashboard3.jpg";
import hub from "./folio/mhw/control/hubFront.jpg";
import login from "./folio/mhw/control/login.jpg";

//maps

import mapfirst from "./folio/map/mapDesktop1.jpg";
import second from "./folio/map/mapDesktop2.jpg";
import third from "./folio/map/mapDesktop3.jpg";
import fourth from "./folio/map/mapDesktop4.jpg";
import fifth from "./folio/map/mapmobile1.jpg";
import sixth from "./folio/map/mapmobile2.jpg";

//dir

import dir1 from "./folio/dir/desktopCRM1.png";
import dir2 from "./folio/dir/desktopCRM2.png";
import dir3 from "./folio/dir/mobileCRM1.png";
import dir4 from "./folio/dir/mobileCRM2.png";

//crm
import crm from "./folio/crm/crm.png";
import crm1 from "./folio/crm/crm1.png";
import crm2 from "./folio/crm/crm2.png";

//gog
import gogreen from "./folio/gog/gogreen.png";
import gog1 from "./folio/gog/gog1.png";
import gog2 from "./folio/gog/gog2.png";
import gog3 from "./folio/gog/gog3.png";
import gog4 from "./folio/gog/gog4.png";
import gog5 from "./folio/gog/gog5.png";
import gog6 from "./folio/gog/gog6.png";

//nda
import nda1 from "./folio/nda/nda1.png";
import nda2 from "./folio/nda/nda2.png";
import nda3 from "./folio/nda/nda3.png";
import nda4 from "./folio/nda/nda4.png";
import nda5 from "./folio/nda/nda5.png";
import nda6 from "./folio/nda/nda6.png";
import nda7 from "./folio/nda/nda7.png";
import nda8 from "./folio/nda/nda8.png";
import nda9 from "./folio/nda/nda9.png";
import nda91 from "./folio/nda/nda91.png";

//ppi
import ppi from "./folio/ppi/ppi.png";
import ppi1 from "./folio/ppi/ppi1.png";
import ppi2 from "./folio/ppi/ppi2.png";
import ppi3 from "./folio/ppi/ppi3.png";
import ppi4 from "./folio/ppi/ppi4.png";
import ppi5 from "./folio/ppi/ppi5.png";

export interface AssetTypes {
  [key: string]: any;
}

// Define the nested asset types
export interface NestedAssetTypes {
  [key: string]: AssetTypes | any;
}

const assets: NestedAssetTypes = {
  mhw: {
    userlogin,
    chat,
    book,
    book1,
    access,
    dash1,
    dash2,
    dash3,
    hub,
    login,
    proLogin,
    proReg,
    first,
    spec2,
    spec3,
  },
  map: {
    mapfirst,
    second,
    third,
    fourth,
    fifth,
    sixth,
  },
  dir: {
    dir1,
    dir2,
    dir3,
    dir4,
  },
  crm: {
    crm,
    crm1,
    crm2,
  },
  gog: {
    gogreen,
    gog1,
    gog2,
    gog3,
    gog4,
    gog5,
    gog6,
  },
  nda: {
    nda1,
    nda2,
    nda3,
    nda4,
    nda5,
    nda6,
    nda7,
    nda8,
    nda9,
    nda91,
  },
  ppi: {
    ppi,
    ppi1,
    ppi2,
    ppi3,
    ppi4,
    ppi5,
  },
  resetIcon,
  // ppi,
  // nda,
  // gogreen,
  // crm,
  specks,
  crud,
  clips,
  wave,
};

export { assets };
