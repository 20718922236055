import React, { useRef, useState, useEffect } from "react";
import { useSpring, a } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { Title, Frame, Content, toggle } from "../../styling/styles";
import * as Icons from "../../styling/icons";
import "./tree.scss";

interface TreeProps extends React.HTMLAttributes<HTMLDivElement> {
  defaultOpen?: boolean;
  name: string | JSX.Element;
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const Tree: React.FC<TreeProps> = React.memo(({ children, name, style, defaultOpen = false }) => {
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [ref, { height: viewHeight }] = useMeasure();
  const { height, opacity, y } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 20,
    },
  });

  const Icon: React.FC<React.HTMLAttributes<HTMLDivElement>> =
    Icons[`${children ? (isOpen ? "Minus" : "Plus") : "Close"}SquareO`];

  const toggleOpen = () => {
    setOpen(!isOpen);
  };

  return (
    <Frame>
      <Icon
        className="treeIcon"
        style={{ ...toggle, opacity: children ? 1 : 0.3 }}
        onClick={toggleOpen}
      />
      <Title styleID={name} data-text={name} style={style} onClick={toggleOpen}>
        {name}
      </Title>
      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
        }}
      >
        <a.div ref={ref} style={{ y }} children={children} />
      </Content>
    </Frame>
  );
});

export default Tree;
