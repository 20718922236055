import { FavoriteBorder, FlightTakeoff } from "@mui/icons-material";
import axios from "axios";
import React, { useState, ChangeEvent } from "react";
import { assets } from "../../assets";
import {
  Orange,
  OrangeBranch,
  OrangeJuice,
  OrangePeel,
  OrangePip,
} from "../../styling/styles";

interface EmailValidatorProps {}

const EmailValidator: React.FC<EmailValidatorProps> = () => {
  const [email, setEmail] = useState("");
  const [emailInput, setEmailInput] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);

  const submitIconStyle = {
    color: valid ? "white" : "grey",
    cursor: "pointer",
  };

  const validateEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(emailValue);
    setValid(isValid);
  };

  const submit = () => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = regex.test(email);

    if (isValid) {
      setValid(isValid);
      axios
        .post("./php/sendEmail.php", email)
        .then(() => {
          setEmailInput(false);
          setSuccess(!success);
          setValid(false);
          setError(false);
        })
        .catch(() => setError(!error));
    } else {
      setError(!error);
    }
  };

  return (
    <OrangeBranch>
      {emailInput && (
        <>
          <Orange src={assets.wave} alt="clips" />
          <OrangePeel>
            <OrangePip> with an email </OrangePip>
            <OrangeJuice
              type="email"
              value={email}
              onChange={validateEmail}
              onInput={validateEmail}
            />
          </OrangePeel>
          <FlightTakeoff onClick={submit} style={submitIconStyle} />
        </>
      )}

      {success && <FavoriteBorder />}
      {error && <p>uh oh, let's try that again</p>}
    </OrangeBranch>
  );
};

export default EmailValidator;
