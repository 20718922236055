import styled, { keyframes } from "styled-components";
import { animated } from "@react-spring/web";
import { css } from "styled-components";

export const Container = styled("div")`
  display: flex;
  justify-content: space-around;
  width: 70%;
  @media (max-width: 768px) {
    width: 92%;
  }
  height: auto;
  margin: 0 auto 5rem;
  padding: 5rem 0rem;
  font-size: ${(props: { fontSize: any }) => props.fontSize + "em"};
  line-height: ${(props: { fontSize: any }) => props.fontSize + 0.44 + "em"};
  --webkit-user-select: none;
  user-select: none;
  color: ${(props: { fontColor: any }) => props.fontColor};
  fill: #f5f5f5;
  /* border: 1px red solid; */
`;

export const Frame = styled("div")`
  /* border: 1px blue solid; */
  position: relative;
  padding: 4px 0px 0px 0px;
  vertical-align: middle;
  height: auto;
  width: 90%;
  @media (max-width: 768px) {
    width: 98%;
  }
`;

const waveAnimation = keyframes`
  0% {
    opacity: 0.7;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
`;

export const Title = styled("span")`
  vertical-align: middle;
  cursor: pointer;
  position: relative;
  display: inline-block;
  font-size: 1.5em;
  padding-bottom: 5px;
  @media (max-width: 768px) {
    font-size: 1em;
  }

  &:hover::before,
  &:hover::after {
    animation: ${waveAnimation} 0.4s linear;
    animation-delay: 0.2s;
  }

  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &:hover::before {
    opacity: 1;
  }

  &:hover::after {
    opacity: 0;
  }

  ${(props: { styleID: string }) =>
    props.styleID === "National Debt Advisers" &&
    css`
      @media (max-width: 768px) {
        font-size: 0.9em;
      }
    `}
`;

export const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  margin-left: 6px;
  padding: 0px 0px 10px 14px;
  border-left: 1px dashed rgba(255, 255, 255, 0.4);
  height: auto;
`;

export const toggle = {
  width: "1em",
  height: "1em",
  marginRight: 10,
  cursor: "pointer",
  verticalAlign: "middle",
};

export const TreeImageWrappers = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 12rem;
  height: 12rem;
  border-radius: 10px;
  background-color: #0f0f0f;
  padding: 5%;
  z-index: 8;
`;

export const TreeLeaves = styled("img")`
  width: 100%;
  height: auto;
  object-fit: contain;
  position: relative;
  border-radius: 10px;
  z-index: 9;
  cursor: pointer;
  transition: transform 0.3s;
`;

export const TreeApples = styled("img")`
  position: absolute;
  width: 1em;
  height: 1em;
  border-radius: 10px;
  right: 5px;
  bottom: 5px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  cursor: pointer;
  z-index: 10;
`;

export const OrangeBranch = styled("div")`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const orangeSwing = keyframes`
  0% {
    transform: rotate(-18deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(-18deg);
  }
`;

export const Orange = styled("img")`
  width: 1.2em;
  height: 1.2em;
  padding: 8px;
  animation: ${orangeSwing} 1s linear infinite;
`;

export const OrangePeel = styled("div")`
  display: flex;
  align-items: end;
  flex-direction: column;
`;

export const OrangePip = styled("p")`
  margin: 0;
  padding: 0;
  font-size: 0.6em;
`;

export const OrangeJuice = styled("input")`
  margin: 0;
  padding: 0;
  font-family: "Cutive Mono", monospace;
  padding: 5px;
  font-weight: 600;
`;

export const Cherry = styled("p")`
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  height: auto;
  width: 96%;
`;
